/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql } from "gatsby"
import { RichText } from "prismic-reactjs"
import { Box, Flex } from "rebass"
import { uniqueId } from "lodash"

import {
  Container,
  Layout,
  Section,
} from "@giraldomac/gatsby-theme-mmdbase/src/components/layout"
import { SEO } from "@giraldomac/gatsby-theme-mmdbase/src/components/elements"
import { PubHero } from "../../../components/elements"

const ReportsOrdinances = ({ data }) => {
  const page = data.prismic.reports__ordinances

  return (
    <Layout>
      <SEO
        meta_title={page.meta_title}
        meta_description={page.meta_description}
      />
      <PubHero title={RichText.asText(page.title)} />
      <Section>
        <Container>
          <Flex flexWrap="wrap" justifyContent="center">
            <Box
              width={["full", 4 / 5, 3 / 4]}
              sx={{
                backgroundColor: "wrapperbox",
                padding: [4, 5],
              }}
            >
              {RichText.render(page.content)}

              {page.body.map(({ primary, fields }) => (
                <Box key={uniqueId("section_id")}>
                  {RichText.render(primary.title_of_section)}
                  <ul
                    sx={{
                      columnCount: [1, 2],
                      columnGap: [0, 4],
                      listStyle: "none",
                      margin: 0,
                    }}
                  >
                    {fields.map(({ link_to_document, file_name }) => (
                      <li>
                        <a href={link_to_document.url}>
                          {RichText.asText(file_name)}
                        </a>
                      </li>
                    ))}
                  </ul>
                  <hr sx={{ mt: [3, 4] }} />
                </Box>
              ))}
            </Box>
          </Flex>
        </Container>
      </Section>
    </Layout>
  )
}

export default ReportsOrdinances

export const query = graphql`
  {
    prismic {
      reports__ordinances(lang: "en-us", uid: "reports-ordinances") {
        content
        meta_description
        meta_title
        title
        body {
          ... on PRISMIC_Reports__ordinancesBodyList_of_articles {
            type
            label
            fields {
              link_to_document {
                _linkType
                ... on PRISMIC__FileLink {
                  _linkType
                  url
                  name
                  size
                }
              }
              file_name
            }
            primary {
              title_of_section
            }
          }
        }
      }
    }
  }
`
